
import { Options, Vue } from 'vue-class-component'
import { defineAsyncComponent } from 'vue'

import DilypseResellerTab from './DilypseResellerTab.vue'

const DilypseResellerStatistic = defineAsyncComponent(
  () => import('./DilypseResellerStatistic.vue')
)
const DilypseResellerAffiliate = defineAsyncComponent(
  () => import('./DilypseResellerAffiliate.vue')
)
const DilypseResellerProduct = defineAsyncComponent(
  () => import('./DilypseResellerProduct.vue')
)

@Options({
  name: 'DilypseReseller',
  components: {
    DilypseResellerTab,
    DilypseResellerStatistic,
    DilypseResellerAffiliate,
    DilypseResellerProduct,
  },
  data() {
    return {
      viewValue: 'DilypseResellerStatistic',
      menu: [
        {
          text: 'Statistiques',
          name: 'DilypseResellerStatistic',
        },
        {
          text: 'Liste des affiliés',
          name: 'DilypseResellerAffiliate',
        },
        {
          text: 'Gestion des produits',
          name: 'DilypseResellerProduct',
        },
      ],
    }
  },
})
export default class DilypseReseller extends Vue {}

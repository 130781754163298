
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'DilypseResellerTab',
  props: {
    menu: {
      type: Array,
      required: true,
    },
    defaultActive: {
      type: String,
      default: 'dashboard',
    },
    modelValue: String,
  },
  emits: ['navigate', 'input', 'update:modelValue'],
  data: function () {
    return {
      active: this.modelValue || this.defaultActive,
    }
  },
  methods: {
    onShowDisplayPage: function (type: string) {
      this.active = type
      this.$emit('navigate', type)
      this.$emit('update:modelValue', this.active)
    },
  },
})
export default class DilypseResellerTab extends Vue {}

<template>
  <div class="header-left">
    <div class="d-flex">
      <nav class="nav nav-tabs">
        <a
          v-for="m in menu"
          :key="m.name"
          class="nav-link"
          :class="{ active: active === m.name }"
          @click.prevent="onShowDisplayPage(m.name)"
          >{{ m.text }}</a
        >
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'DilypseResellerTab',
  props: {
    menu: {
      type: Array,
      required: true,
    },
    defaultActive: {
      type: String,
      default: 'dashboard',
    },
    modelValue: String,
  },
  emits: ['navigate', 'input', 'update:modelValue'],
  data: function () {
    return {
      active: this.modelValue || this.defaultActive,
    }
  },
  methods: {
    onShowDisplayPage: function (type: string) {
      this.active = type
      this.$emit('navigate', type)
      this.$emit('update:modelValue', this.active)
    },
  },
})
export default class DilypseResellerTab extends Vue {}
</script>

<style lang="scss" scoped>
.content-header {
  margin-bottom: 30px;
}
</style>
